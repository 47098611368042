import React, { useState, useEffect } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { CheckIcon } from '@heroicons/react/24/outline';
import { FiCopy } from 'react-icons/fi';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Highlight from '@tiptap/extension-highlight';
import TextAlign from '@tiptap/extension-text-align';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore'; // NEW: Firestore methods
import HomePage from './HomePage';
import { FaStar } from 'react-icons/fa';
import { marked } from 'marked';
import { track, identify } from './analytics';
import { getAuth, signInWithPopup, GoogleAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";import { auth } from "./firebase";
import {
  FaBold,
  FaItalic,
  FaUnderline,
  FaStrikethrough,
  FaHeading,
  FaListOl,
  FaListUl,
  FaQuoteLeft,
  FaRedo,
  FaUndo,
  FaAlignLeft,
  FaAlignCenter,
  FaAlignRight,
  FaAlignJustify,
  FaTrash,
  FaSave,
} from 'react-icons/fa';


const featuredTestimonial = {
  body: 'I cannot believe I used to waste time formatting all my lecture notes, I feel so much more prepared going into class!',
  author: {
    name: 'Lisa Phillips',
    handle: 'University Student',
    imageUrl:
      'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=1024&h=1024&q=80',
  },
}

const testimonials = [
  [
    [
      {
        body: 'SpeedNote AI has completely changed how I approach my studies. Instead of spending hours reformatting and organizing my notes, I can focus on understanding the material. It’s like having a study partner that knows exactly what I need!',
        author: {
          name: 'Jonathan Greene',
          handle: 'PhD Researcher',
          imageUrl:
    'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'        },
      },
      // More testimonials...
    ],
    [
      {
        body: 'As someone who juggles school and a part-time job, SpeedNote AI is a lifesaver. It formats my notes, highlights key points, and even adds helpful comments, making review sessions so much more effective.',
        author: {
          name: 'Emily Roberts',
          handle: 'Undergraduate Student',
          imageUrl:
            'https://images.unsplash.com/photo-1488426862026-3ee34a7d66df?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
      },
      // More testimonials...
    ],
  ],
  [
    [
      {
        body: 'Using SpeedNote AI for my business meetings has been a game-changer. It keeps my notes organized and even adds relevant insights that I hadn’t considered. Now, I leave every meeting feeling confident that I have all the important details at my fingertips.',
        author: {
          name: 'Michael Harris',
          handle: 'Business Consultant',
          imageUrl:
          'https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',        },
      },
      // More testimonials...
    ],
    [
      {
        body: 'SpeedNote AI has streamlined my lecture preparation process. Instead of getting bogged down by formatting, I can dive straight into the material and focus on learning. This tool is a must-have for anyone serious about their studies!',
        author: {
          name: 'Sophia Lee',
          handle: 'Graduate Student',
          imageUrl:
            'https://images.unsplash.com/photo-1531251445707-1f000e1e87d0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
      },
      // More testimonials...
    ],
  ],
]


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
function LandingPage() {
  const [inputText, setInputText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [title, setTitle] = useState('Notes');
  const [originalInputs, setOriginalInputs] = useState([]);
  const [showOriginalInputs, setShowOriginalInputs] = useState(false);
  const [date, setDate] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [passwordLengthError, setPasswordLengthError] = useState("");
  const [loginError, setLoginError] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [generateCount, setGenerateCount] = useState(0);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const db = getFirestore(); // NEW: Firestore instance
  const [tutOpen, setTutOpen] = useState(false);
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const LoadingSpinner = () => (
    <div className="flex justify-center items-center h-full">
      <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-purple-500"></div>
    </div>
  );

  const tutorialSteps = [
    {
      title: "Write Your Notes",
      description: "Jot down your notes as fast as you want.",
      video: "https://hermesimages.s3.amazonaws.com/writey.mp4"
    },
    {
      title: "Shortcut to Generate",
      description: "Press CMD+Enter to generate notes on the fly.",
      video: "https://hermesimages.s3.amazonaws.com/generate.mp4"
    },
    {
      title: "Our Top Tips",
      description: "Get the most out of SpeedNote with these helpful tips.",
      tips: [
        "Don't worry about grammar or spelling - we'll clean that up for you, just focus on getting your content down.",
        "If you want SpeedNote to format for you in a specific way, just ask it in brackets: (underline this) or (bullet these).",
        "Use CMD/CTRL+Shift to quickly generate and append more notes to your existing document.",
        "If you miss content or information, just ask in brackets and the AI will fill it in for you: (add the 7 wonders of the world here).",
        "Experiment with different note-taking styles to find what works best for you, some people prefer writing in shorter bursts, others would rather only generate infrequently.",
        "Review and edit your generated notes for personal touch and accuracy.",
        "Copy your notes over to OneNote, Word or any note taking platform - or save them with SpeedNote and edit later."
      ]
    }
  ];
    

  const [currentStep, setCurrentStep] = useState(0);

  const nextStep = () => {
    setCurrentStep((prevStep) => (prevStep + 1) % tutorialSteps.length);
    setIsVideoLoading(true);
  };
  
  const previousStep = () => {
    setCurrentStep((prevStep) => (prevStep - 1 + tutorialSteps.length) % tutorialSteps.length);
    setIsVideoLoading(true);
  };

  const openEmailModal = () => {
    // Close all other modals
    setModalOpen(false);
    setLoginModalOpen(false);
    // Open the email modal
    setEmailModalOpen(true);
  };
  
  const openLoginModal = () => {
    // Close all other modals
    setModalOpen(false);
    setEmailModalOpen(false);
    // Open the login modal
    setLoginModalOpen(true);
  };
  
  useEffect(() => {
    const storedIsAuthenticated = localStorage.getItem('isAuthenticated');
    if (storedIsAuthenticated === 'true') {
      setIsAuthenticated(true);
    }
  }, []);

  const createUserDocument = async (user, isPremium = false) => {
    const userRef = doc(db, 'Users', user.uid);
    console.log("Attempting to create or check user document in Firestore for UID:", user.uid);
    try {
      const userSnap = await getDoc(userRef);
      if (!userSnap.exists()) {
        console.log("User document does not exist, creating one...");
        await setDoc(userRef, {
          email: user.email,
          isPremium: isPremium,
          createdAt: new Date()  // Store the current date and time
        });
        console.log("User document successfully created for:", user.email);
      } else {
        console.log("User document already exists, no need to create.");
      }
    } catch (error) {
      console.error("Error accessing Firestore:", error);
    }
  };
  

  const handleGoogleSignIn = async () => {
    track('Google Sign In Attempt');
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      console.log("Google sign-in successful");
      setModalOpen(false);
      setGenerateCount(0);
      localStorage.removeItem('generateCount');
      localStorage.setItem('isAuthenticated', 'true');
      await createUserDocument(result.user);
      setIsAuthenticated(true);
      track('Google Sign In Success', { userId: result.user.uid });
      identify(result.user.uid);
      window.location.reload();
    } catch (error) {
      console.error("Google sign-in error:", error);
      track('Google Sign In Error', { error: error.message });
    }
  };
  

  const handleEmailSignUp = async (e) => {
    e.preventDefault();
    track('Email Sign Up Attempt');
    const email = e.target.elements.email.value;
    const password = e.target.elements.password.value;
    const confirmPassword = e.target.elements.confirmPassword.value;
  
    if (password.length < 6) {
      setPasswordLengthError("Password should be at least 6 characters");
      return;
    }
  
    setPasswordLengthError(""); // Clear the password length error if password is long enough
  
    if (password !== confirmPassword) {
      // Handle password mismatch error
      setPasswordError("Passwords do not match");
      return;
    }
  
    setPasswordError(""); // Clear the password error if passwords match
  
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      // Handle successful sign-up
      console.log("Email sign-up successful");
      setEmailModalOpen(false);
      setGenerateCount(0);
      localStorage.removeItem('generateCount');
      localStorage.setItem('isAuthenticated', 'true');
      await createUserDocument(userCredential.user); // Properly referenced 'userCredential.user'
      setIsAuthenticated(true);
      track('Email Sign Up Success', { userId: userCredential.user.uid });
      identify(userCredential.user.uid);
      window.location.reload();
    } catch (error) {
      console.error("Email sign-up error:", error);
      track('Email Sign Up Error', { error: error.message });
    }
  };
  
  const handleEmailLogin = (e) => {
    e.preventDefault();
    track('Email Login Attempt');
    const email = e.target.elements.email.value;
    const password = e.target.elements.password.value;
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log("Email login successful");
        setLoginModalOpen(false);
        setLoginError("");
        setGenerateCount(0);
        localStorage.removeItem('generateCount');
        localStorage.setItem('isAuthenticated', 'true');
        setIsAuthenticated(true);
        track('Email Login Success', { userId: userCredential.user.uid });
        identify(userCredential.user.uid);
        window.location.reload();
      })
      .catch((error) => {
        console.error("Email login error:", error);
        setLoginError("Invalid email or password. Please try again.");
        track('Email Login Error', { error: error.message });
      });
  };

  const handleResetPassword = () => {
    const email = prompt("Please enter your email address:");
    if (email) {
      sendPasswordResetEmail(auth, email)
        .then(() => {
          // Password reset email sent!
          alert("Password reset email sent. Please check your inbox.");
        })
        .catch((error) => {
          // Error occurred while sending password reset email
          console.error("Error sending password reset email:", error);
          alert("Failed to send password reset email. Please try again.");
        });
    }
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
      Image,
      Link,
      Placeholder.configure({ placeholder: 'Start typing your notes...' }),
    ],
    content: '',
  });

  useEffect(() => {
    track('Page View', { page: 'Landing Page' });
  }, []);

  const trackInteraction = () => {
    track('User Interaction', { page: 'Landing Page' });
  };


  useEffect(() => {
    if (editor && editor.commands) {
      editor.commands.setContent(editor.getHTML());
    }
  }, [editor]);

  useEffect(() => {
    const currentDate = new Date().toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
    setDate(currentDate);
  }, []);
  
  const handleGenerateNotes = async () => {
    track('Generate Notes Attempt', { wordCount });
    if (wordCount > 300) {
      return;
    }

    setGenerateCount((prevCount) => {
      const newCount = prevCount + 1;
      localStorage.setItem('generateCount', newCount);
      return newCount;
    });
  
    if (generateCount >= 3) {
      setModalOpen(true);
      return;
    }
  

    setIsLoading(true);
    setError(null);
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/generateNotes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ inputText }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to generate notes');
      }
  
      const data = await response.json();
      console.log('API Response:', data);
  
      if (data.notes) {
        const html = marked(data.notes);
        const currentContent = editor.getHTML();
        const newContent = currentContent + html;
        editor.commands.setContent(newContent);
        console.log('Output Text:', data.notes);
        setOriginalInputs((prevInputs) => [...prevInputs, inputText]);
        setInputText(''); // Clear the input text
      } else {
        throw new Error('Invalid API response format');
      }
    } catch (err) {
      console.error('Error:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const storedCount = localStorage.getItem('generateCount');
    if (storedCount) {
      setGenerateCount(parseInt(storedCount));
    }
  }, []);

  const handleCopyNotes = () => {
    const notesHTML = editor.getHTML();
    navigator.clipboard.write([
      new ClipboardItem({
        "text/html": new Blob([notesHTML], { type: "text/html" }),
        "text/plain": new Blob([notesHTML], { type: "text/plain" })
      })
    ]).then(() => {
      console.log('Notes copied to clipboard');
    }).catch((error) => {
      console.error('Failed to copy notes:', error);
    });
  };
  
  const handleKeyDown = (e) => {
    if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
      handleGenerateNotes();
    }
  };

  const toggleBold = () => {
    editor.chain().focus().toggleBold().run();
  };

  const toggleItalic = () => {
    editor.chain().focus().toggleItalic().run();
  };

  const toggleUnderline = () => {
    editor.chain().focus().toggleUnderline().run();
  };

  const toggleStrike = () => {
    editor.chain().focus().toggleStrike().run();
  };

  const toggleHeading = (level) => {
    editor.chain().focus().toggleHeading({ level }).run();
  };

  const toggleBulletList = () => {
    editor.chain().focus().toggleBulletList().run();
  };

  const setTextAlign = (alignment) => {
    editor.chain().focus().setTextAlign(alignment).run();
  };

  const undo = () => {
    editor.chain().focus().undo().run();
  };

  const redo = () => {
    editor.chain().focus().redo().run();
  };

  return (
<div className="min-h-screen flex flex-col items-center justify-start bg-gradient-to-b from-purple-100 to-white pt-10" onClick={trackInteraction}>
      <header className="text-center p-6">
      <a 
  href="https://theresanaiforthat.com/ai/speednote-ai/?ref=featured&v=1250710" 
  target="_blank" 
  rel="nofollow noopener noreferrer" 
  className="inline-block mb-4 transition-transform duration-300 hover:scale-105"
>
  <img 
    src="https://media.theresanaiforthat.com/featured-on-taaft.png?width=600" 
    alt="Featured on There's An AI For That" 
    className="w-auto h-[50px] max-w-full object-contain"
  />
</a>
      <h1 className="text-6xl font-bold mb-6 font-header">Write clear notes. <span className="text-purple-500">Fast.</span></h1>
      <p className="text-xl mb-6 max-w-3xl mx-auto font-body font-semibold text-gray-700">
          SpeedNote uses AI to convert your typo-riddled mess into clear notes that are easy to read.
        </p>
      <p className="text-lg mb-8 font-body">Rapidly create lecture notes, meeting minutes, essays and more. All you have to do is type.</p>
      <div className="space-x-4">
      <button
  className="bg-purple-500 text-white font-bold py-2 px-6 rounded-full shadow-md"
  onClick={() => {
    setModalOpen(true);
    track('Sign Up Button Click');
  }}
>
  sign up / log in
</button>
<button 
  className="bg-white text-gray-800 font-bold py-2 px-6 rounded-full shadow-md border"
  onClick={() => {
    setTutOpen(true);
    track('Tutorial Button Click');
  }}
>
  how does it work
</button>
      </div>
    </header>

{/* Auth Modal component */}
<Transition show={modalOpen}>
  <Dialog className="relative z-10" onClose={() => setModalOpen(false)}>
    <Transition.Child
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
    </Transition.Child>
    <div className="fixed inset-0 z-10 overflow-y-auto">
    <div className="flex min-h-full items-start justify-center p-4 text-center pt-60">
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <Dialog.Panel className="relative transform overflow-hidden rounded-xl bg-white px-6 py-5 text-left shadow-xl transition-all sm:w-full sm:max-w-sm">
          <h3 className="text-xl font-semibold text-gray-900 mb-2 font-merriweather text-center">
              Sign up or Log in
            </h3>
            <p className="text-sm text-gray-500 font-merriweather text-center mb-4">
              Your first 10 notes are free, no credit card required
            </p>
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-full bg-purple-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-purple-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500 focus-visible:ring-offset-2 font-merriweather"
              onClick={handleGoogleSignIn}
           >
              sign in with Google
            </button>
            <div className="mt-4">
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-full border border-gray-300 px-4 py-2 text-base font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500 focus-visible:ring-offset-2 font-merriweather"
              onClick={openEmailModal}  // Added onClick event handler here
            >
              or sign up / log in with email
            </button>
            </div>
            <p className="mt-2 text-sm text-gray-500 font-merriweather">
              By signing up, you agree to SpeedNote's Privacy Policy and Terms of Use.
            </p>
          </Dialog.Panel>
        </Transition.Child>
      </div>
    </div>
  </Dialog>
</Transition>

      {/* Tutorial Modal Component */}
      <Transition show={tutOpen}>
        <Dialog as="div" className="relative z-10" onClose={() => setTutOpen(false)}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all sm:max-w-2xl">
  <h3 className="text-2xl text-center font-semibold text-gray-900 mb-4">
    {tutorialSteps[currentStep].title}
  </h3>
  <p className="text-lg text-center text-gray-500 mb-6">
    {tutorialSteps[currentStep].description}
  </p>
  <div className="relative mb-6">
  {tutorialSteps[currentStep].video ? (
    <div className="aspect-w-16 aspect-h-9" style={{ borderColor: 'purple', borderWidth: '4px', borderStyle: 'solid', borderRadius: '12px', overflow: 'hidden' }}>
      {isVideoLoading && <LoadingSpinner />}
      <video
        src={tutorialSteps[currentStep].video}
        autoPlay
        loop
        muted
        playsInline
        className={`w-full h-full object-cover ${isVideoLoading ? 'hidden' : ''}`}
        onLoadedData={() => setIsVideoLoading(false)}
      >
        Your browser does not support the video tag.
      </video>
    </div>

    ) : (
      <div className="bg-purple-100 p-6 rounded-lg border-2 border-purple-300">
        <h4 className="text-xl font-semibold text-purple-700 mb-4">Pro Tips:</h4>
        <ul className="list-disc pl-5 space-y-2">
          {tutorialSteps[currentStep].tips.map((tip, index) => (
            <li key={index} className="text-gray-700">{tip}</li>
          ))}
        </ul>
      </div>
    )}
  </div>
  <div className="flex justify-between">
    <button
      onClick={previousStep}
      className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-3 px-6 rounded-lg text-lg"
    >
      Prev
    </button>
    <button
      onClick={nextStep}
      className="bg-purple-500 hover:bg-purple-600 text-white font-bold py-3 px-6 rounded-lg text-lg"
    >
      Next
    </button>
  </div>
</Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      
{/* Signup Modal Component */}
<Transition show={emailModalOpen}>
    <Dialog className="relative z-10" onClose={() => setEmailModalOpen(false)}>
  <Transition.Child
    enter="ease-out duration-300"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="ease-in duration-200"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
  </Transition.Child>
  <div className="fixed inset-0 z-10 overflow-y-auto">
  <div className="flex min-h-full items-start justify-center p-4 text-center pt-60">
      <Transition.Child
        enter="ease-out duration-300"
        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enterTo="opacity-100 translate-y-0 sm:scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <Dialog.Panel className="relative transform overflow-hidden rounded-xl bg-white px-6 py-5 text-left shadow-xl transition-all sm:max-w-sm">
        <h3 className="text-xl text-center font-semibold text-gray-900 mb-6">
              Sign up
            </h3>
            <form onSubmit={handleEmailSignUp}>
              <div className="mb-4">
                <input
                  type="email"
                  name="email"
                  className="w-full px-4 py-3 border border-gray-300 rounded-full text-gray-900 placeholder-gray-500 focus:ring-purple-500 focus:border-purple-500"
                  placeholder="Email"
                  required
                />
              </div>
              <div className="mb-4">
                <input
                  type="password"
                  name="password"
                  className="w-full px-4 py-3 border border-gray-300 rounded-full text-gray-900 placeholder-gray-500 focus:ring-purple-500 focus:border-purple-500"
                  placeholder="Password"
                  required
                />
              </div>
              {passwordLengthError && (
              <p className="text-red-500 text-sm mb-4">{passwordLengthError}</p>
              )}
              <div className="mb-4">
                <input
                  type="password"
                  name="confirmPassword"
                  className="w-full px-4 py-3 border border-gray-300 rounded-full text-gray-900 placeholder-gray-500 focus:ring-purple-500 focus:border-purple-500"
                  placeholder="Confirm Password"
                  required
                />
              </div>
              {passwordError && (
              <p className="text-red-500 text-sm mb-4">{passwordError}</p>
              )}
              <button
                type="submit"
                className="w-full bg-purple-500 hover:bg-purple-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 text-white rounded-full px-4 py-3 transition duration-150 ease-in-out"
              >
                Sign up
              </button>
            </form>
            <p className="mt-4 text-sm text-gray-500">
              By signing up, you agree to our Privacy Policy and Terms of Service.
            </p>
            <div className="mt-6 border-t border-gray-300 pt-4">
              <button
                className="w-full bg-white hover:bg-gray-50 text-gray-700 rounded-full px-4 py-3 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                onClick={openLoginModal}
              >
                Already have an account? Login
              </button>
            </div>
          </Dialog.Panel>
        </Transition.Child>
      </div>
    </div>
  </Dialog>
</Transition>

{/* Login Modal Component */}
<Transition show={loginModalOpen}>
    <Dialog className="relative z-10" onClose={() => setLoginModalOpen(false)}>
  <Transition.Child
    enter="ease-out duration-300"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="ease-in duration-200"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
  </Transition.Child>
  <div className="fixed inset-0 z-10 overflow-y-auto">
  <div className="flex min-h-full items-start justify-center p-4 text-center pt-60">
      <Transition.Child
        enter="ease-out duration-300"
        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enterTo="opacity-100 translate-y-0 sm:scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <Dialog.Panel className="relative transform overflow-hidden rounded-xl bg-white px-6 py-5 text-left shadow-xl transition-all sm:max-w-sm">
          <h3 className="text-xl text-center font-semibold text-gray-900 mb-6">
            Log in
          </h3>
          <form onSubmit={handleEmailLogin}>
          <div className="mb-4">
                <input
                  type="email"
                  name="email"
                  className="w-full px-4 py-3 border border-gray-300 rounded-full text-gray-900 placeholder-gray-500 focus:ring-purple-500 focus:border-purple-500"
                  placeholder="Email"
                  required
                />
              </div>
              <div className="mb-4">
                <input
                  type="password"
                  name="password"
                  className="w-full px-4 py-3 border border-gray-300 rounded-full text-gray-900 placeholder-gray-500 focus:ring-purple-500 focus:border-purple-500"
                  placeholder="Password"
                  required
                />
              </div>
              {loginError && (
                <p className="text-red-500 text-sm mb-4">{loginError}</p>
              )}
              <button
                type="submit"
                className="w-full bg-purple-500 hover:bg-purple-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 text-white rounded-full px-4 py-3 transition duration-150 ease-in-out"
              >
                Login
              </button>
            <div className="mt-4 text-center">
              <button
                type="button"
                className="text-purple-500 hover:text-purple-400 focus:outline-none"
                onClick={handleResetPassword}
              >
                Forgot password?
              </button>
              </div>
            </form>
            <p className="mt-4 text-sm text-gray-500">
              By logging in, you agree to our Privacy Policy and Terms of Service.
            </p>
            <div className="mt-6 border-t border-gray-300 pt-4">
              <button
                className="w-full bg-white hover:bg-gray-50 text-gray-700 rounded-full px-4 py-3 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                onClick={openEmailModal}
              >
                Don't have an account? Signup.
              </button>
            </div>
        </Dialog.Panel>
      </Transition.Child>
    </div>
  </div>
</Dialog>
</Transition>



<div className="w-full max-w-2xl mt-10 relative">
  <div className="relative">
    <textarea
      className="w-full h-48 p-4 pb-12 border-4 border-purple-500 rounded-lg focus:outline-none focus:border-purple-700"
      placeholder="Start writing your notes here..."
      value={inputText}
      onChange={(e) => {
        const text = e.target.value;
        setInputText(text);
        setWordCount(text.trim().split(/\s+/).length);
      }}
      onKeyDown={handleKeyDown}
    ></textarea>
    <div className="absolute bottom-4 left-4 text-sm">
      <span className={`${wordCount <= 300 ? 'text-gray-500' : 'text-red-500'}`}>
        {wordCount}/300
      </span>
    </div>
    <button
      className="absolute bottom-4 right-4 bg-purple-500 text-white font-bold py-2 px-4 rounded-full shadow-md"
      onClick={handleGenerateNotes}
      disabled={isLoading || wordCount > 300}
    >
      {isLoading ? 'Generating...' : 'Generate Notes'} <span className="text-sm text-gray-200">(Cmd/Ctrl+Enter)</span>
    </button>
  </div>
</div>
<div className="w-full max-w-2xl mt-10 p-6 mb-6 bg-gray-800 text-white rounded-xl shadow-lg relative">
  <div className="flex justify-between items-center mb-4">
    <div className="mb-1">
      <input
        type="text"
        value={date}
        onChange={(e) => setDate(e.target.value)}
        placeholder={date}
        className="text-gray-400 text-sm bg-transparent outline-none"
      />
    </div>
    <FaTrash
      className="text-white cursor-pointer"
      size={20}
      onClick={() => editor.commands.clearContent()}
    />
  </div>
  <input
    type="text"
    value={title}
    onChange={handleTitleChange}
    className="text-3xl font-bold mb-2 bg-transparent outline-none"
  />
  <div className="bg-purple-500 h-1 w-8 mb-4"></div>
  <div className="overflow-x-auto mb-4">
  <div className="flex items-center space-x-2 min-w-max">
    <button onClick={toggleBold} className="p-2 bg-gray-700 rounded-md focus:outline-none"><FaBold /></button>
    <button onClick={toggleItalic} className="p-2 bg-gray-700 rounded-md focus:outline-none"><FaItalic /></button>
    <button onClick={toggleUnderline} className="p-2 bg-gray-700 rounded-md focus:outline-none"><FaUnderline /></button>
    <button onClick={toggleStrike} className="p-2 bg-gray-700 rounded-md focus:outline-none"><FaStrikethrough /></button>
    <button onClick={() => toggleHeading(2)} className="p-2 bg-gray-700 rounded-md focus:outline-none"><FaHeading /></button>
    <button onClick={toggleBulletList} className="p-2 bg-gray-700 rounded-md focus:outline-none"><FaListUl /></button>
    <button onClick={() => setTextAlign('left')} className="p-2 bg-gray-700 rounded-md focus:outline-none"><FaAlignLeft /></button>
    <button onClick={() => setTextAlign('center')} className="p-2 bg-gray-700 rounded-md focus:outline-none"><FaAlignCenter /></button>
    <button onClick={() => setTextAlign('right')} className="p-2 bg-gray-700 rounded-md focus:outline-none"><FaAlignRight /></button>
    <button onClick={() => setTextAlign('justify')} className="p-2 bg-gray-700 rounded-md focus:outline-none"><FaAlignJustify /></button>
    <button onClick={undo} className="p-2 bg-gray-700 rounded-md focus:outline-none"><FaUndo /></button>
    <button onClick={redo} className="p-2 bg-gray-700 rounded-md focus:outline-none"><FaRedo /></button>
    </div>
         </div>
        <div className="mb-6 markdown">
  <EditorContent editor={editor} />
</div>
<div className="flex items-center justify-between mt-4">
  <FaSave
    className="text-white cursor-pointer"
    size={24}
    onClick={() => setModalOpen(true)}
    />
  <div className="flex items-center">
    <button
      className="p-2 bg-purple-500 px-6 text-white rounded-full font-bold shadow-md focus:outline-none"
      onClick={() => setShowOriginalInputs(!showOriginalInputs)}
    >
      View Original Notes
    </button>
    <FiCopy
      className="text-white ml-4 cursor-pointer"
      size={24}
      onClick={handleCopyNotes}
    />
  </div>
</div>
{showOriginalInputs && (
  <div className="w-full max-w-2xl mt-2 p-6 bg-white rounded-xl shadow-lg accordion">
    <h3 className="text-xl font-bold text-gray-800 mb-4">Original Notes</h3>
    {originalInputs.map((input, index) => (
      <div key={index} className="mb-4">
        <p className="text-gray-700">{input}</p>
      </div>
    ))}
  </div>
)}
</div>
{error && <div className="text-red-500 mt-4">{error}</div>}


      {/* Testimonial Section */}
      <div className="relative isolate pb-32 pt-24 sm:pt-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-xl text-center">
            <h2 className="text-lg font-semibold leading-8 tracking-tight text-purple-500">Testimonials</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              SpeedNote has already revolutionised efficiency for so many
            </p>
          </div>
          <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm leading-6 text-gray-900 sm:mt-20 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">
          <figure className="rounded-2xl bg-white shadow-lg ring-1 ring-gray-900/5 sm:col-span-2 xl:col-start-2 xl:row-end-1">
              <blockquote className="p-6 text-lg font-semibold leading-7 tracking-tight text-gray-900 sm:p-12 sm:text-xl sm:leading-8">
                <p>{`"${featuredTestimonial.body}"`}</p>
              </blockquote>
              <figcaption className="flex flex-wrap items-center gap-x-4 gap-y-4 border-t border-gray-900/10 px-6 py-4 sm:flex-nowrap">
                <img
                  className="h-10 w-10 flex-none rounded-full bg-gray-50"
                  src={featuredTestimonial.author.imageUrl}
                  alt=""
                />
                <div className="flex-auto">
                  <div className="font-semibold">{featuredTestimonial.author.name}</div>
                  <div className="text-gray-600">{`${featuredTestimonial.author.handle}`}</div>
                </div>
                <div className="flex items-center">
                  {[...Array(5)].map((_, i) => (
                    <FaStar key={i} className="w-8 h-8 text-purple-400" />
                  ))}
                </div>
              </figcaption>
            </figure>
            {testimonials.map((columnGroup, columnGroupIdx) => (
              <div key={columnGroupIdx} className="space-y-8 xl:contents xl:space-y-0">
                {columnGroup.map((column, columnIdx) => (
                  <div
                    key={columnIdx}
                    className={classNames(
                      (columnGroupIdx === 0 && columnIdx === 0) ||
                        (columnGroupIdx === testimonials.length - 1 &&
                          columnIdx === columnGroup.length - 1)
                        ? 'xl:row-span-2'
                        : 'xl:row-start-1',
                      'space-y-8'
                    )}
                  >
                    {column.map((testimonial) => (
                      <figure
                        key={testimonial.author.handle}
                        className="rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5"
                      >
                        <blockquote className="text-gray-900">
                          <p>{`“${testimonial.body}”`}</p>
                        </blockquote>
                        <figcaption className="mt-6 flex items-center gap-x-4">
                          <img
                            className="h-10 w-10 rounded-full bg-gray-50"
                            src={testimonial.author.imageUrl}
                            alt=""
                          />
                          <div>
                            <div className="font-semibold">{testimonial.author.name}</div>
                            <div className="text-gray-600">{`${testimonial.author.handle}`}</div>
                          </div>
                        </figcaption>
                      </figure>
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        <p className="text-xs text-gray-400 text-center mt-8">
        * Authentic testimonials from verified users. Personal details have been modified to preserve anonymity.</p>
      </div>
      {/* Footer */}
      <footer className="text-center py-4 mt-8 border-t border-gray-200">
        <a href="/tos" className="text-sm text-gray-500 hover:text-gray-900 mx-4">Terms of Service</a>
        <a href="/privacy" className="text-sm text-gray-500 hover:text-gray-900 mx-4">Privacy Policy</a>
        <a href="/contact" className="text-sm text-gray-500 hover:text-gray-900 mx-4">Contact</a>
      </footer>
    </div>
  );
};

export default LandingPage;