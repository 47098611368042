import React, { useState, useEffect } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { CheckIcon } from '@heroicons/react/24/outline';
import { FiCopy } from 'react-icons/fi';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Highlight from '@tiptap/extension-highlight';
import TextAlign from '@tiptap/extension-text-align';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import HomePage from './HomePage';
import { marked } from 'marked';
import { db } from './firebase';
import { track, identify } from './analytics';
import { collection, addDoc } from 'firebase/firestore';
import { query, doc, updateDoc, orderBy, deleteDoc, where, getDocs } from 'firebase/firestore';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css'; // Core Swiper styles
import 'swiper/css/autoplay'; // Autoplay module styles
import 'swiper/css/pagination'; // Pagination module styles
import 'swiper/css/navigation'; // Navigation module styles

// Import Swiper core and required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import { loadStripe } from '@stripe/stripe-js';


import { getAuth, deleteUser, signInWithPopup, GoogleAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, sendPasswordResetEmail } from "firebase/auth";import { auth } from "./firebase";
import {
  FaBold,
  FaItalic,
  FaUnderline,
  FaStrikethrough,
  FaHeading,
  FaListOl,
  FaListUl,
  FaQuoteLeft,
  FaRedo,
  FaUndo,
  FaAlignLeft,
  FaAlignCenter,
  FaAlignRight,
  FaAlignJustify,
  FaTrash,
  FaUserCircle,
  FaSave,
} from 'react-icons/fa';

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update the state to force render
}

const stripePromise = loadStripe('pk_live_51PSguSJoDBwiYlRqUSEeFC8SbChVTvkQ6bvJoYzzu6fZypENUpwml20GpijSOxFWuC4VNw2oWn6gGue2mmTZTj1000FXIcmxQX');

const frequencies = [
    { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
  ]
  const tiers = [
    {
      name: 'SpeedNote Pro',
      id: 'tier-startup',
      href: '/signup',
      price: { monthly: '$18.99', annually: 'not applicable' },
      discountPrice: { monthly: '$12.99', annually: 'not applicable' },       
      description: 'Become a productivity master.',
      features: [
        'Unlimited note generation',
        '1000 per-generation word limit',
        'No cool-down period',
        'Access to our advanced AI model',
        'Early access to upcoming Boost-Mode - leverage the smartest model for diagrams and complex reasoning',
        'Save unlimited personal notes',
        'Early access to new features',
        'Insert images into files',
        'Feature request priority',
      ],
      mostPopular: true,
    },
  ]

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

function NotesAreaNonPremium() {
  const [inputText, setInputText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [title, setTitle] = useState('Notes');
  const [originalInputs, setOriginalInputs] = useState([]);
  const [showOriginalInputs, setShowOriginalInputs] = useState(false);
  const [date, setDate] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [passwordLengthError, setPasswordLengthError] = useState("");
  const [loginError, setLoginError] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [generateCount, setGenerateCount] = useState(0);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [notes, setNotes] = useState([]);  // Add this line to hold fetched notes
  const [selectedNote, setSelectedNote] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const [frequency, setFrequency] = useState(frequencies[0])
  const [timeLeft, setTimeLeft] = useState({});
  const resetTimerDisplay = Object.keys(timeLeft).length
  ? `${timeLeft.hours}h ${timeLeft.minutes}m ${timeLeft.seconds}s until reset`
  : 'No active timer';  // or any other default message when there's no active timer
  const [tutOpen, setTutOpen] = useState(false);
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const LoadingSpinner = () => (
    <div className="flex justify-center items-center h-full">
      <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-purple-500"></div>
    </div>
  );

  const tutorialSteps = [
    {
      title: "Write Your Notes",
      description: "Jot down your notes as fast as you want.",
      video: "https://hermesimages.s3.amazonaws.com/writey.mp4"
    },
    {
      title: "Shortcut to Generate",
      description: "Press CMD+Enter to generate notes on the fly.",
      video: "https://hermesimages.s3.amazonaws.com/generate.mp4"
    },
    {
      title: "Our Top Tips",
      description: "Get the most out of SpeedNote with these helpful tips.",
      tips: [
        "Don't worry about grammar or spelling - we'll clean that up for you, just focus on getting your content down.",
        "If you want SpeedNote to format for you in a specific way, just ask it in brackets: (underline this) or (bullet these).",
        "Use CMD/CTRL+Shift to quickly generate and append more notes to your existing document.",
        "If you miss content or information, just ask in brackets and the AI will fill it in for you: (add the 7 wonders of the world here).",
        "Experiment with different note-taking styles to find what works best for you, some people prefer writing in shorter bursts, others would rather only generate infrequently.",
        "Review and edit your generated notes for personal touch and accuracy.",
        "Copy your notes over to OneNote, Word or any note taking platform - or save them with SpeedNote and edit later."
      ]
    }
  ];
    
  const [currentStep, setCurrentStep] = useState(0);

  const nextStep = () => {
    setCurrentStep((prevStep) => (prevStep + 1) % tutorialSteps.length);
  };

  const previousStep = () => {
    setCurrentStep((prevStep) => (prevStep - 1 + tutorialSteps.length) % tutorialSteps.length);
  };



    // Initialize the modal editor at the top level of your component
    const modalEditor = useEditor({
        extensions: [
          StarterKit,
          Underline,
          Highlight,
          TextAlign.configure({ types: ['heading', 'paragraph'] }),
          Image,
          Link,
          Placeholder.configure({ placeholder: 'Type here...' }),
        ],
        content: '',
      });
    
  const openEditModal = (note) => {
    track('Edit Note', { noteId: note.id, isPremium: false });
    setSelectedNote(note);
    modalEditor.commands.setContent(note.content);
    setModalTitle(note.title); // Use the new state for the modal title
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  }
  
  const handleUpgrade = async () => {
    track('Upgrade to Pro Initiated', { 
      from: 'Notes Area',
      currentGenerateCount: generateCount,
      remainingGenerations: 15 - generateCount
    });
    
    const stripe = await stripePromise;
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: auth.currentUser.uid }),
      });
  
      const session = await response.json();
  
      track('Stripe Checkout Started', { sessionId: session.sessionId });
  
      const result = await stripe.redirectToCheckout({
        sessionId: session.sessionId,
      });
  
      if (result.error) {
        track('Stripe Checkout Error', { error: result.error.message });
        alert(result.error.message);
      }
    } catch (error) {
      track('Upgrade Process Error', { error: error.message });
      console.error('Error in upgrade process:', error);
    }
  };

  useEffect(() => {
    const storedIsAuthenticated = localStorage.getItem('isAuthenticated');
    if (storedIsAuthenticated === 'true') {
      setIsAuthenticated(true);
    }
  }, []);

  const deleteNote = async () => {
    track('Delete Note Attempt', { noteId: selectedNote.id, isPremium: false });
    // Confirm with the user before deleting
    if (window.confirm('Are you sure you want to delete this note? This action cannot be undone.')) {
      if (selectedNote && selectedNote.id) {
        // Get a reference to the Firestore document
        const noteRef = doc(db, 'notes', selectedNote.id);
  
        try {
          // Call deleteDoc with the document reference
          await deleteDoc(noteRef);
          console.log('Note deleted successfully');
  
          // Update the notes state by filtering out the deleted note
          setNotes(prevNotes => prevNotes.filter(note => note.id !== selectedNote.id));
  
          // Close the modal and show a confirmation message
          closeModal();
          alert('Note deleted successfully.');
        } catch (error) {
          console.error('Error deleting note:', error);
          alert('Failed to delete the note.');
        }
      }
    }
  };

  const updateNote = async () => {
    track('Update Note Attempt', { noteId: selectedNote.id, isPremium: false });
    if (!isAuthenticated) {
        alert('You must be logged in to save notes.');
        return;
    }

    // Check if there is a selected note to update
    if (!selectedNote || !selectedNote.id) {
        alert("No selected note to update.");
        return;
    }

    // Prepare the data from the modal editor and other inputs
    const noteContent = modalEditor.getHTML(); // Fetches the HTML content from the modal Tiptap editor
    const noteTitle = modalTitle; // Uses the modal title state variable
    const noteDate = date; // Uses the date state variable

    // Get a reference to the Firestore document
    const noteRef = doc(db, 'notes', selectedNote.id);

    try {
        // Update the document
        await updateDoc(noteRef, {
            title: noteTitle,
            content: noteContent,
            date: noteDate,
            updatedAt: new Date() // Optionally capture the time the note was updated
        });
        console.log('Note updated successfully');

        // Optionally update local state or perform any other actions
        alert('Note updated successfully!');
        fetchNotes(); // Refetch notes after a successful save
        closeModal(); // Close the modal if desired
    } catch (error) {
        console.error('Error updating document: ', error);
        alert('Error updating note.');
    }
};
  
const saveNote = async () => {
  track('Save Note Attempt', { isPremium: false });
  console.log("Save note called; isAuthenticated: ", isAuthenticated);
  if (!isAuthenticated) {
      alert('You must be logged in to save notes.');
      return;
  }
  
  // Check if editor is defined
  if (!editor) {
      console.error("Editor not initialized");
      return;
  }

  const noteContent = editor.getHTML(); // This fetches the HTML content from the Tiptap editor
  const noteTitle = title; // This uses the 'title' state variable
  const userId = auth.currentUser?.uid; // Getting the user ID from the authenticated user

  // First check the number of notes the user already has
  const notesQuery = query(collection(db, 'notes'), where('userId', '==', userId));
  const querySnapshot = await getDocs(notesQuery);
  if (querySnapshot.size >= 3) {
      setUpgradeModalOpen(true); // Open the upgrade modal if over word limit
      return;
  }

  console.log("User ID: ", userId);
  console.log("Note Title: ", noteTitle);
  console.log("Note Content: ", noteContent);

  try {
      const docRef = await addDoc(collection(db, 'notes'), {
          userId,
          title: noteTitle,
          content: noteContent,
          createdAt: new Date() // Capturing the time the note was saved
      });
      console.log('Document written with ID: ', docRef.id);
      track('Note Saved', { title: noteTitle, contentLength: noteContent.length, isPremium: false });
      alert('Note saved successfully!');
      fetchNotes(); // Refetch notes after a successful save
  } catch (e) {
      console.error('Error adding document: ', e);
      track('Note Save Error', { error: e.message, isPremium: false });
      alert('Error saving note.');
  }
};

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const toggleUpgradeModal = () => {
    setUpgradeModalOpen(!upgradeModalOpen);
  };

  const handleLogout = () => {
    track('User Logout', { page: 'Notes Area (Non-Premium)' });
    const auth = getAuth();
    signOut(auth).then(() => {
      // Sign-out successful.
      localStorage.removeItem('isAuthenticated');
      console.log('Logout successful');
      window.location.reload();  // Optional: force reload to clear all app state
    }).catch((error) => {
      // An error happened.
      console.error('Logout error:', error);
    });
  };

  const handleDeleteAccount = () => {
    track('Delete Account Initiated', { isPremium: false });
    if (window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
      handleDeleteRead();
    }
  };
  
  const handleDeleteRead = () => {
    const auth = getAuth();
    const user = auth.currentUser;
  
    deleteUser(user).then(() => {
      // User deleted.
      localStorage.removeItem('isAuthenticated'); // Clean up local storage or any other stored state
      console.log('Account deleted successfully');
      window.location.reload(); // Optionally force a reload
    }).catch((error) => {
      // An error occurred
      console.error('Error deleting user account:', error);
      alert('Failed to delete account. Please re-sign in and try again.'); // Inform the user of the failure
    });
  };
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
      Image,
      Link,
      Placeholder.configure({ placeholder: 'Start typing your notes...' }),
    ],
    content: '',
  });
  
  useEffect(() => {
    console.log("useEffect triggered for auth state listening");
  
    // Listener for authentication state
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        console.log("User signed in:", user);
        fetchNotes();
      } else {
        console.log("No user signed in.");
      }
    });
  
    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);
  
  const fetchNotes = async () => {
    console.log("Starting to fetch notes");
  
    // Ensure the query includes ordering by 'createdAt' field
    const q = query(
      collection(db, 'notes'),
      where('userId', '==', auth.currentUser.uid),
      orderBy('createdAt', 'desc') // This will order the notes by the creation date in descending order
    );
    console.log("Query constructed:", q);
  
    const querySnapshot = await getDocs(q);
    console.log("Received query snapshot");
  
    const fetchedNotes = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    console.log("Fetched notes:", fetchedNotes);
  
    setNotes(fetchedNotes);
    console.log("Notes set in state");
  };
  
  // Define a function to truncate note content
function truncateContent(content, maxLength = 100) {
    return content.length > maxLength ? content.substring(0, maxLength) + '...' : content;
  }
  
  useEffect(() => {
    if (!editor) return;
  
    const handleUpdate = () => {
      const htmlContent = editor.getHTML();
      localStorage.setItem('editorContent', htmlContent);
    };
  
    editor.on('update', handleUpdate);
  
    // Cleanup function to avoid memory leaks
    return () => editor.off('update', handleUpdate);
  }, [editor]);

  useEffect(() => {
    const savedContent = localStorage.getItem('editorContent');
    if (editor && savedContent) {
      editor.commands.setContent(savedContent);
    }
  }, [editor]);
  

  useEffect(() => {
    if (editor && editor.commands) {
      editor.commands.setContent(editor.getHTML());
    }
  }, [editor]);

  useEffect(() => {
    const currentDate = new Date().toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
    setDate(currentDate);
  }, []);

  const calculateTimeLeft = () => {
    const resetTimestamp = parseInt(localStorage.getItem('resetTimestamp'), 10);
    const currentTime = new Date().getTime();
    const difference = resetTimestamp + 86400000 - currentTime; // 24 hours after the timestamp
  
    if (difference > 0) {
      return {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return {};
  };
  
  // Update the timer every second
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    track('Page View', { page: 'Notes Area (Non-Premium)' });
  }, []);
  
  const handleGenerateNotes = async () => {
    track('Generate Notes Attempt', { wordCount, isPremium: false });
    const currentTime = new Date().getTime();
    const resetTime = parseInt(localStorage.getItem('resetTimestamp'), 10);
  
    // Check if the current time is less than 24 hours from the last reset time
    if (resetTime && (currentTime - resetTime < 43200000)) {
      setUpgradeModalOpen(true); // Open the upgrade modal if over word limit
      return;
    }
  
    // Reset the generation count and timestamp after 24 hours
    if (resetTime && (currentTime - resetTime >= 43200000)) {
      localStorage.setItem('generateCount', '0');
      localStorage.removeItem('resetTimestamp');
      setGenerateCount(0);
    }
  
    if (generateCount >= 15) {
      localStorage.setItem('resetTimestamp', currentTime.toString());
      setUpgradeModalOpen(true); // Open the upgrade modal if over word limit
      return;
    }
  
    if (wordCount > 300) {
      setUpgradeModalOpen(true); // Open the upgrade modal if over word limit
      return;
    }
  
    setIsLoading(true);
    setError(null);
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/generateNotes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ inputText }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to generate notes');
      }
  
      const data = await response.json();
      console.log('API Response:', data);
  
      if (data.notes) {
        track('Notes Generated', { wordCount: wordCount, outputLength: data.notes.length, isPremium: false });
        const html = marked(data.notes);
        const currentContent = editor.getHTML();
        const newContent = currentContent + html;
        editor.commands.setContent(newContent);
        console.log('Output Text:', data.notes);
  
        setOriginalInputs(prevInputs => [...prevInputs, inputText]);
        setInputText(''); // Clear the input text
  
        // Increment the generation count and store in localStorage
        setGenerateCount(prevCount => {
          const newCount = prevCount + 1;
          localStorage.setItem('generateCount', newCount.toString());
          return newCount;
        });
      } else {
        throw new Error('Invalid API response format');
      }
    } catch (err) {
      track('Note Generation Error', { error: err.message, isPremium: false });
      console.error('Error:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    // Load the generate count from local storage or set it to 0 if not found
    const storedCount = localStorage.getItem('generateCount');
    setGenerateCount(storedCount ? parseInt(storedCount, 10) : 0);
  }, []);
  
  const handleCopyNotes = () => {
    const notesHTML = editor.getHTML();
    navigator.clipboard.write([
      new ClipboardItem({
        "text/html": new Blob([notesHTML], { type: "text/html" }),
        "text/plain": new Blob([notesHTML], { type: "text/plain" })
      })
    ]).then(() => {
      console.log('Notes copied to clipboard');
    }).catch((error) => {
      console.error('Failed to copy notes:', error);
    });
  };
  
  const handleKeyDown = (e) => {
    if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
      handleGenerateNotes();
    }
  };

  const toggleBold = () => {
    editor.chain().focus().toggleBold().run();
  };

  const toggleItalic = () => {
    editor.chain().focus().toggleItalic().run();
  };

  const toggleUnderline = () => {
    editor.chain().focus().toggleUnderline().run();
  };

  const toggleStrike = () => {
    editor.chain().focus().toggleStrike().run();
  };

  const toggleHeading = (level) => {
    editor.chain().focus().toggleHeading({ level }).run();
  };

  const toggleBulletList = () => {
    editor.chain().focus().toggleBulletList().run();
  };

  const setTextAlign = (alignment) => {
    editor.chain().focus().setTextAlign(alignment).run();
  };

  const undo = () => {
    editor.chain().focus().undo().run();
  };

  const redo = () => {
    editor.chain().focus().redo().run();
  };
  
  // Modal editor functions
const modalToggleBold = () => {
    modalEditor.chain().focus().toggleBold().run();
  };
  
  const modalToggleItalic = () => {
    modalEditor.chain().focus().toggleItalic().run();
  };
  
  const modalToggleUnderline = () => {
    modalEditor.chain().focus().toggleUnderline().run();
  };
  
  const modalToggleStrike = () => {
    modalEditor.chain().focus().toggleStrike().run();
  };
  
  const modalToggleHeading = (level) => {
    modalEditor.chain().focus().toggleHeading({ level }).run();
  };
  
  const modalToggleBulletList = () => {
    modalEditor.chain().focus().toggleBulletList().run();
  };
  
  const modalSetTextAlign = (alignment) => {
    modalEditor.chain().focus().setTextAlign(alignment).run();
  };
  
  const modalUndo = () => {
    modalEditor.chain().focus().undo().run();
  };
  
  const modalRedo = () => {
    modalEditor.chain().focus().redo().run();
  };

  return (
    
  <div className="min-h-screen flex flex-col items-center justify-start bg-gradient-to-b from-purple-100 to-white pt-10">
    <header className="text-center p-6">
      <h1 className="text-5xl font-bold mb-2 font-header">SpeedNote <span className="text-purple-500">AI</span></h1>
      <div className="space-x-4 mt-10">
      <button
  className="w-55 bg-purple-500 text-white font-bold py-2 px-6 rounded-full shadow-md"
  onClick={() => {
    track('Upgrade Button Clicked', { location: 'Top of Notes Area' });
    toggleUpgradeModal();
  }}
>
  upgrade to pro
</button>
  <button 
  className="bg-white text-gray-800 font-bold py-2 px-6 rounded-full shadow-md border"
  onClick={() => {
    setTutOpen(true);
    track('Tutorial Opened', { page: 'Notes Area (Non-Premium)' });
  }}
>
  tutorial
</button>
</div>

    </header>


      {/* User Settings Icon and Modal */}
      <div className="absolute top-4 right-6">
        <button onClick={() => setSettingsModalOpen(true)} className="text-purple-500 hover:text-purple-600">
        <FaUserCircle className="h-10 w-10" />
        </button>
      </div>

      <Transition show={settingsModalOpen}>
      <Dialog as="div" className="relative z-10" onClose={() => setSettingsModalOpen(false)}>
    <Transition.Child
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
    </Transition.Child>
    <div className="fixed inset-0 z-10 overflow-y-auto">
    <div className="flex min-h-full items-start justify-center p-4 text-center pt-60">
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <Dialog.Panel className="relative transform overflow-hidden rounded-xl bg-white px-6 py-5 text-left shadow-xl transition-all sm:w-full sm:max-w-sm">
          <h3 className="text-xl font-semibold text-gray-900 mb-2 font-merriweather text-center">
              User Settings
            </h3>
            <p className="text-sm text-gray-500 font-merriweather text-center mb-4">
              Member
            </p>
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-full bg-purple-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-purple-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500 focus-visible:ring-offset-2 font-merriweather"
              onClick={toggleUpgradeModal}
           >
              Upgrade to Pro
            </button>
            <div className="mt-4">
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-full border border-gray-300 px-4 py-2 text-base font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500 focus-visible:ring-offset-2 font-merriweather"
              onClick={handleLogout}
              >
              Logout
              </button>
              </div>
              <div className="mt-4">
              <button
              type="button"
              className="inline-flex w-full justify-center rounded-full border border-gray-300 px-4 py-2 text-base font-medium text-gray-700 bg-white hover:bg-red-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500 focus-visible:ring-offset-2 font-merriweather"
              onClick={handleDeleteAccount}
              >
              Delete account
              </button>
            </div>
            <p className="mt-2 text-sm text-center text-gray-500 font-merriweather">
              For more support, please email us.
            </p>
          </Dialog.Panel>
        </Transition.Child>
      </div>
    </div>
  </Dialog>
</Transition>

      {/* Upgrade Modal */}
      <div className="absolute top-4 right-6">
        <button onClick={() => setUpgradeModalOpen(true)} className="text-purple-500 hover:text-purple-600">
        </button>
      </div>

      <Transition show={upgradeModalOpen}>
  <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={() => setUpgradeModalOpen(false)}>
    <Transition.Child
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
    </Transition.Child>
    <div className="flex items-center justify-center min-h-screen">
      <Transition.Child
        enter="ease-out duration-300"
        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enterTo="opacity-100 translate-y-0 sm:scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <Dialog.Panel className="relative transform overflow-hidden rounded-xl bg-gradient-to-b from-purple-100 to-white pt-10 w-11/12 max-w-4xl mx-auto">
          <div className="px-6 py-8">
          <h3 className="text-5xl text-center font-bold mb-2 font-header">SpeedNote <span className="text-purple-500">Pro</span></h3>
            <p className="text-lg text-gray-500 font-merriweather text-center mb-6">
              Take your notetaking ability to the next level.
            </p>
            <div className="bg-purple-500 h-1 w-8 mb-1 mx-auto"></div>
            <div className="mt-1 flex justify-center">
    </div>
    <div className="mx-auto mt-10">
    <div className="max-w-md mx-auto">
          {tiers.map((tier) => (
        <div
          key={tier.id}
          className={classNames(
            tier.mostPopular ? 'ring-2 ring-purple-600' : 'ring-1 ring-gray-200',
            'rounded-3xl p-8 xl:p-10'
          )}
        >
          <div className="flex items-center justify-between gap-x-4">
            <h3
              id={tier.id}
              className={classNames(
                tier.mostPopular ? 'text-purple-600' : 'text-gray-900',
                'text-lg font-semibold leading-8'
              )}
            >
              {tier.name}
            </h3>
            {tier.mostPopular ? (
              <p className="rounded-full bg-purple-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-purple-600">
                Limited Sale 🔥
              </p>
            ) : null}
          </div>
          <p className="mt-4 text-sm leading-6 text-gray-600">{tier.description}</p>
          <p className="mt-6 flex items-baseline">
              <span className="text-2xl font-bold tracking-tight text-gray-500" style={{ textDecoration: 'line-through' }}>
                {tier.price[frequency.value]}
              </span>
              <span className="text-4xl font-bold tracking-tight text-gray-900 ml-1">
                {tier.discountPrice[frequency.value]}
              </span>
              <span className="text-sm font-semibold leading-6 text-gray-600">{frequency.priceSuffix}</span>
            </p>
            <button
  href={tier.href}
  aria-describedby={tier.id}
  className={classNames(
    tier.mostPopular
      ? 'bg-purple-600 text-white shadow-sm hover:bg-purple-500'
      : 'text-purple-600 ring-1 ring-inset ring-purple-200 hover:ring-purple-300',
    'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600'
  )}
  onClick={() => {
    track('Get SpeedNote Pro Clicked', { 
      price: tier.discountPrice[frequency.value],
      frequency: frequency.value
    });
    handleUpgrade();
  }}
>
  Get SpeedNote Pro
</button>
            <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10">
            {tier.features.map((feature) => (
              <li key={feature} className="flex gap-x-3">
                <CheckIcon className="h-6 w-5 flex-none text-purple-600" aria-hidden="true" />
                {feature}
              </li>
            ))}
          </ul>
        </div>
      ))}
            <p className="mt-6 text-lg text-center text-gray-500 font-merriweather">
             30-Day Money-Back Guarantee. Cancel Anytime.
            </p>
            </div>

            <div className="bg-purple-500 h-1 w-8 mt-4 mx-auto"></div>
            <div className="px-6 py-8">
          <h1 className="text-3xl text-center font-bold mb-2 font-header">Testimonials</h1>
            <p className="text-lg text-gray-500 font-merriweather text-center mb-6">
              See what others are saying about SpeedNote <span className="text-purple-500">Pro</span>
            </p>

            <Swiper
  modules={[Autoplay, Pagination, Navigation]}
  spaceBetween={30}
  centeredSlides={true}
  autoplay={{
    delay: 5000,
    disableOnInteraction: false,
  }}
  pagination={{
    clickable: true,
  }}
  navigation={false}
  className="mySwiper"
>
          <SwiperSlide>
        <div className="text-center">
          <p className="text-xl text-gray-700 font-merriweather mb-4">"I <strong>love</strong> SpeedNote because I can write in my own words without AI trying to change it, <em>it let's me focus on content</em> while the AI focuses on formatting."</p>
          <p className="text-lg text-gray-500 font-merriweather">- Riley Mayer</p>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="text-center">
        <p className="text-xl text-gray-700 font-merriweather mb-4">"Geniunely the <strong>best</strong> thing I've bought for college. Thank you!"</p>
          <p className="text-lg text-gray-500 font-merriweather">- Kelly Marriman</p>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="text-center">
        <p className="text-xl text-gray-700 font-merriweather mb-4">"Now I <strong>don't have to worry about typos or formatting</strong>, finally I'm not missing what the lecturer is saying and I can focus on getting content down"</p>
          <p className="text-lg text-gray-500 font-merriweather">- Max Segura</p>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="text-center">
          <p className="text-xl text-gray-700 font-merriweather mb-4">"SpeedNote has quickly become <strong>my go-to for all things notetaking</strong>, I can't believe how much time I'm saving."</p>
          <p className="text-lg text-gray-500 font-merriweather">- Louis Bagoli</p>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="text-center">
          <p className="text-xl text-gray-700 font-merriweather mb-4">"I've tried several note-taking apps, but <strong>SpeedNote Pro stands out from the rest</strong>. Its intuitive interface and powerful features make it a <strong>game-changer</strong>. Highly recommended!"</p>
          <p className="text-lg text-gray-500 font-merriweather">- Michelle Silverman</p>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="text-center mb-7">
          <p className="text-xl text-gray-700 font-merriweather mb-4">"As a student, SpeedNote Pro has become an <strong>essential tool</strong> for me. It helps me capture important information during lectures and keeps my notes well-structured. It's a <strong>lifesaver</strong>!"</p>
          <p className="text-lg text-gray-500 font-merriweather">- Mark Johnson</p>
        </div>
      </SwiperSlide>
    </Swiper>
            <div className="bg-purple-500 h-1 w-8 mb-1 mt-4 mx-auto"></div>
          </div>
          </div>
          </div>
        </Dialog.Panel>
      </Transition.Child>
    </div>
  </Dialog>
</Transition>

      {/* Tutorial Modal Component */}
      <Transition show={tutOpen}>
        <Dialog as="div" className="relative z-10" onClose={() => setTutOpen(false)}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all sm:max-w-2xl">
  <h3 className="text-2xl text-center font-semibold text-gray-900 mb-4">
    {tutorialSteps[currentStep].title}
  </h3>
  <p className="text-lg text-center text-gray-500 mb-6">
    {tutorialSteps[currentStep].description}
  </p>
  <div className="relative mb-6">
  {tutorialSteps[currentStep].video ? (
    <div className="aspect-w-16 aspect-h-9" style={{ borderColor: 'purple', borderWidth: '4px', borderStyle: 'solid', borderRadius: '12px', overflow: 'hidden' }}>
      {isVideoLoading && <LoadingSpinner />}
      <video
        src={tutorialSteps[currentStep].video}
        autoPlay
        loop
        muted
        playsInline
        className={`w-full h-full object-cover ${isVideoLoading ? 'hidden' : ''}`}
        onLoadedData={() => setIsVideoLoading(false)}
      >
        Your browser does not support the video tag.
      </video>
    </div>

    ) : (
      <div className="bg-purple-100 p-6 rounded-lg border-2 border-purple-300">
        <h4 className="text-xl font-semibold text-purple-700 mb-4">Pro Tips:</h4>
        <ul className="list-disc pl-5 space-y-2">
          {tutorialSteps[currentStep].tips.map((tip, index) => (
            <li key={index} className="text-gray-700">{tip}</li>
          ))}
        </ul>
      </div>
    )}
  </div>
  <div className="flex justify-between">
    <button
      onClick={previousStep}
      className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-3 px-6 rounded-lg text-lg"
    >
      Prev
    </button>
    <button
      onClick={nextStep}
      className="bg-purple-500 hover:bg-purple-600 text-white font-bold py-3 px-6 rounded-lg text-lg"
    >
      Next
    </button>
  </div>
</Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>


      {/* Input Sections */}

      <div className="w-full max-w-2xl mt-10 relative">
  <div className="text-center mb-4">
    <span className="text-lg font-bold">{15 - generateCount} generations left today</span>
    <span className="text-sm text-gray-500"> (upgrade to pro for limitless notetaking)</span>
  </div>
  {/* Your textarea and other UI elements here */}
</div>
<div className="w-full max-w-2xl mt-10 relative">
  <div className="relative">
    <textarea
      className="w-full h-48 p-4 pb-12 border-4 border-purple-500 rounded-lg focus:outline-none focus:border-purple-700"
      placeholder="Start writing your notes here..."
      value={inputText}
      onChange={(e) => {
        const text = e.target.value;
        setInputText(text);
        setWordCount(text.trim().split(/\s+/).length);
      }}
      onKeyDown={handleKeyDown}
    ></textarea>
    <div className="absolute bottom-4 left-4 text-sm">
      <span className={`${wordCount <= 300 ? 'text-gray-500' : 'text-red-500'}`}>
        {wordCount}/300
      </span>
    </div>
    <button
      className="absolute bottom-4 right-4 bg-purple-500 text-white font-bold py-2 px-4 rounded-full shadow-md"
      onClick={handleGenerateNotes}
      disabled={isLoading || wordCount > 300}
    >
      {isLoading ? 'Generating...' : 'Generate Notes'} <span className="text-sm text-gray-200">(Cmd/Ctrl+Enter)</span>
    </button>
  </div>
</div>
<div className="w-full max-w-2xl mt-10 p-6 mb-6 bg-gray-800 text-white rounded-xl shadow-lg relative">
  <div className="flex justify-between items-center mb-4">
    <div className="mb-1">
      <input
        type="text"
        value={date}
        onChange={(e) => setDate(e.target.value)}
        placeholder={date}
        className="text-gray-400 text-sm bg-transparent outline-none"
      />
    </div>
    <FaTrash
      className="text-white cursor-pointer"
      size={20}
      onClick={() => editor.commands.clearContent()}
    />
  </div>
  <input
    type="text"
    value={title}
    onChange={handleTitleChange}
    className="text-3xl font-bold mb-2 bg-transparent outline-none"
  />
  <div className="bg-purple-500 h-1 w-8 mb-4"></div>
        <div className="flex items-center space-x-2 mb-4">
          <button onClick={toggleBold} className="p-2 bg-gray-700 rounded-md focus:outline-none"><FaBold /></button>
          <button onClick={toggleItalic} className="p-2 bg-gray-700 rounded-md focus:outline-none"><FaItalic /></button>
          <button onClick={toggleUnderline} className="p-2 bg-gray-700 rounded-md focus:outline-none"><FaUnderline /></button>
          <button onClick={toggleStrike} className="p-2 bg-gray-700 rounded-md focus:outline-none"><FaStrikethrough /></button>
          <button onClick={() => toggleHeading(2)} className="p-2 bg-gray-700 rounded-md focus:outline-none"><FaHeading /></button>
          <button onClick={toggleBulletList} className="p-2 bg-gray-700 rounded-md focus:outline-none"><FaListUl /></button>
          <button onClick={() => setTextAlign('left')} className="p-2 bg-gray-700 rounded-md focus:outline-none"><FaAlignLeft /></button>
          <button onClick={() => setTextAlign('center')} className="p-2 bg-gray-700 rounded-md focus:outline-none"><FaAlignCenter /></button>
          <button onClick={() => setTextAlign('right')} className="p-2 bg-gray-700 rounded-md focus:outline-none"><FaAlignRight /></button>
          <button onClick={() => setTextAlign('justify')} className="p-2 bg-gray-700 rounded-md focus:outline-none"><FaAlignJustify /></button>
          <button onClick={undo} className="p-2 bg-gray-700 rounded-md focus:outline-none"><FaUndo /></button>
          <button onClick={redo} className="p-2 bg-gray-700 rounded-md focus:outline-none"><FaRedo /></button>
        </div>
        <div className="mb-6 markdown">
  <EditorContent editor={editor} />
</div>
<div className="flex items-center justify-between mt-4">
  <FaSave
    className="text-white cursor-pointer"
    size={24}
    onClick={saveNote} // Updated to call saveNote function on click
    />
  <div className="flex items-center">
    <button
      className="p-2 bg-purple-500 px-6 text-white rounded-full font-bold shadow-md focus:outline-none"
      onClick={() => setShowOriginalInputs(!showOriginalInputs)}
    >
      View Original Notes
    </button>
    <FiCopy
      className="text-white ml-4 cursor-pointer"
      size={24}
      onClick={() => {
        handleCopyNotes();
        track('Notes Copied', { isPremium: false });
      }}    />
  </div>
</div>
{showOriginalInputs && (
  <div className="w-full max-w-2xl mt-2 p-6 bg-white rounded-xl shadow-lg accordion">
    <h3 className="text-xl font-bold text-gray-800 mb-4">Original Notes</h3>
    {originalInputs.map((input, index) => (
      <div key={index} className="mb-4">
        <p className="text-gray-700">{input}</p>
      </div>
      
    ))}
  </div>
)}
</div>

{/* Add the "Your Notes" section header */}
<div className="w-full max-w-5xl mt-10 text-center">
  <h2 className="text-3xl font-bold text-gray-800 mb-2">Saved Notes</h2>
  <div className="h-1 w-20 bg-purple-500 mx-auto mb-6"></div>
</div>

      {/* Grid for displaying notes */}
      <div className="w-full max-w-5xl grid grid-cols-4 gap-4 mt-4 mb-4">
      {notes.map(note => (
        <div key={note.id} className="p-4 bg-white rounded-xl shadow-lg border-4 border-purple-500" style={{ height: '150px' }} onClick={() => openEditModal(note)}>
        <h4 className="font-bold underline">{note.title}</h4>
        <p className="text-sm text-gray-600" dangerouslySetInnerHTML={{ __html: truncateContent(note.content) }}></p>
        </div>
        ))}
{/* Modal for editing a note */}
<Transition show={modalOpen}>
  <Dialog as="div" className="relative z-10" onClose={closeModal}>
    <Transition.Child
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed inset-0 bg-purple-200 bg-opacity-25 backdrop-filter backdrop-blur-lg" />
    </Transition.Child>
    <div className="fixed inset-0 overflow-y-auto">
      <div className="flex min-h-full items-center justify-center p-4 text-white">
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-xl bg-gray-800 p-6 text-left align-middle shadow-lg transition-all">
            <div className="flex justify-between items-center mb-4">
              <input
                type="text"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                placeholder="YYYY-MM-DD"
                className="text-sm bg-transparent outline-none border-b border-gray-500"
              />
              <FaTrash
                className="cursor-pointer"
                size={20}
                onClick={deleteNote} // Attached deleteNote function here
              />
            </div>
            <input
              type="text"
              value={modalTitle}
              onChange={handleTitleChange}
              className="text-3xl font-bold bg-transparent outline-none"
            />
  <div className="bg-purple-500 h-1 w-8 mb-4"></div>
        <div className="flex items-center space-x-2 mb-4">
        <button onClick={modalToggleBold} className="p-2 bg-gray-700 rounded-md focus:outline-none"><FaBold /></button>
<button onClick={modalToggleItalic} className="p-2 bg-gray-700 rounded-md focus:outline-none"><FaItalic /></button>
<button onClick={modalToggleUnderline} className="p-2 bg-gray-700 rounded-md focus:outline-none"><FaUnderline /></button>
<button onClick={modalToggleStrike} className="p-2 bg-gray-700 rounded-md focus:outline-none"><FaStrikethrough /></button>
<button onClick={() => modalToggleHeading(2)} className="p-2 bg-gray-700 rounded-md focus:outline-none"><FaHeading /></button>
<button onClick={modalToggleBulletList} className="p-2 bg-gray-700 rounded-md focus:outline-none"><FaListUl /></button>
<button onClick={() => modalSetTextAlign('left')} className="p-2 bg-gray-700 rounded-md focus:outline-none"><FaAlignLeft /></button>
<button onClick={() => modalSetTextAlign('center')} className="p-2 bg-gray-700 rounded-md focus:outline-none"><FaAlignCenter /></button>
<button onClick={() => modalSetTextAlign('right')} className="p-2 bg-gray-700 rounded-md focus:outline-none"><FaAlignRight /></button>
<button onClick={() => modalSetTextAlign('justify')} className="p-2 bg-gray-700 rounded-md focus:outline-none"><FaAlignJustify /></button>
<button onClick={modalUndo} className="p-2 bg-gray-700 rounded-md focus:outline-none"><FaUndo /></button>
<button onClick={modalRedo} className="p-2 bg-gray-700 rounded-md focus:outline-none"><FaRedo /></button>
              {/* More buttons for text editing */}
            </div>
            <div className="mb-6 markdown">
  <EditorContent editor={modalEditor} />
</div>
            <div className="mt-4 flex justify-between items-center">
              <button
                className="inline-flex justify-center px-4 py-2 text-sm font-medium bg-purple-500 rounded-md hover:bg-purple-700"
                onClick={updateNote}  // Use updateNote here
                >
                Save Changes
              </button>
              <FiCopy
                className="text-white ml-4 cursor-pointer"
                size={24}
                onClick={handleCopyNotes}
                />
            </div>
          </Dialog.Panel>
        </Transition.Child>
      </div>
    </div>
  </Dialog>
</Transition>
      </div>
{error && <div className="text-red-500 mt-4">{error}</div>}
</div>
);
}

export default NotesAreaNonPremium;